import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PatientDesignPageRoutingModule } from './patient-design-routing.module';

import { PatientDesignPage } from './patient-design.page';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components/shared-components.module';
import { SharedPipesModule } from 'src/app/shared/modules/shared-pipes/shared-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedComponentsModule,
    SharedPipesModule,
    PatientDesignPageRoutingModule
  ],
  declarations: [PatientDesignPage]
})
export class PatientDesignPageModule {}
