import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-customize',
  templateUrl: './header-customize.component.html',
  styleUrls: ['./header-customize.component.scss'],
})
export class HeaderCustomizeComponent implements OnInit {
  @Input() fullname:string;
  constructor() { }

  ngOnInit() {}

  onBack(){ 
    console.log("onBack");
    // window.close();
    window.open("https://apporchard.epic.com/mychart-aocurprd/Home/", '_self');
  }
}
