import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [  
  {
    path:'launch-patient',
    loadChildren: () => import('./modules/patient/launch/launch.module').then( m => m.LaunchPageModule)
  },  
  {
    path: 'launch-doctor',
    loadChildren: () => import('./modules/doctor/launch-doctor/launch-doctor.module').then( m => m.LaunchDoctorPageModule)
  }, 
  {
    path: 'app',
    loadChildren: () => import('./modules/patient/meeting/meeting.module').then( m => m.MeetingPageModule)
  },
  {
    path: 'provider',
    loadChildren: () => import('./modules/doctor/meeting-doctor/meeting-doctor.module').then( m => m.MeetingDoctorPageModule)
  },
  {
    path: 'doctor',
    loadChildren: () => import('./modules/doctor/meeting-doctor/meeting-doctor.module').then( m => m.MeetingDoctorPageModule)
  },
  {
    path: 'doctor-design',
    loadChildren: () => import('./testdesign/doctor-design/doctor-design.module').then( m => m.DoctorDesignPageModule)
  },
  {
    path: 'patient-design',
    loadChildren: () => import('./testdesign/patient-design/patient-design.module').then( m => m.PatientDesignPageModule)
  },   {
    path: 'terms-and-condition',
    loadChildren: () => import('./modules/terms-and-condition/terms-and-condition.module').then( m => m.TermsAndConditionPageModule)
  },
  {
    path: 'emr-ready',
    loadChildren: () => import('./modules/patient/emr-ready/emr-ready.module').then( m => m.EmrReadyPageModule)
  },
  {
    path: 'emr-ready-provider',
    loadChildren: () => import('./modules/doctor/emr-ready-provider/emr-ready-provider.module').then( m => m.EmrReadyProviderPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  }



 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
