import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { environment } from 'src/environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class CustomHttpsService { 
  private base_url  =" ";  
  constructor(private http:HttpClient ) {  
      this.base_url = environment.baseURL; 
  } 

 get(endpoint){  
  const token = localStorage.getItem("_token");
    const url = this.base_url+endpoint;
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('Authorization', "Bearer "+ token) 
    .set('content-type', 'application/json')  

    console.log(url);
    return this.http
    .get(url, { headers: headers })
  } 

  post(endpoint,body){
    const token = localStorage.getItem("_token");
    const url = this.base_url+endpoint;
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('Authorization', "Bearer "+token)
    .set('content-type', 'application/json') 
    console.log("post");
    console.log(body);
    console.log(url); 
    console.log(headers);
    return this.http
    .post(url,body, { headers: headers })
  }

  put(endpoint,body){
    const token = localStorage.getItem("_token");
    const url = this.base_url+endpoint;
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('Authorization', "Bearer "+ token)
    .set('content-type', 'application/json') 
    console.log(body);
    console.log(url);
    return this.http
    .put(url,body, { headers: headers })
  }
  
  del(endpoint){
    const token = localStorage.getItem("_token");
    const url = this.base_url+endpoint;
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('Authorization', "Bearer "+ token)
    .set('content-type', 'application/json') 
    return this.http
    .delete(url, { headers: headers });
  } 

  delWithBody(endpoint,body){
    const token = localStorage.getItem("_token");
    const url = this.base_url+endpoint;
    const headers = new HttpHeaders()
    .set('cache-control', 'no-cache')
    .set('Authorization', "Bearer "+ token)
    .set('content-type', 'application/json') 
    return this.http
    .request('delete', url, { body: body, headers: headers},);
  } 
   
}
