import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentDiscount'
})
export class PercentDiscountPipe implements PipeTransform {

  transform(real_price:number,discounted_price:number): unknown { 
    return (-(100 - (100/ real_price) * discounted_price)).toFixed(2);
  } 
}
