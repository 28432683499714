import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badgeText9only'
})
export class BadgeText9onlyPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {    
    console.log(value);  
    console.log(value > 9 ? '9+': value);
    return value > 9 ? '9+': value;
  } 
}
