import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';  
import { AppointmentFhirModel } from '../models/appointmentfhir.model';

@Injectable({
  providedIn: 'root'
})
export class AppoinmentSearchService {

  constructor(private http:HttpClient) { }

  get(clientID,token,patient){ 
    console.log("get AppoinmentSearchService",token,patient);
    return new Promise<AppointmentFhirModel[]>((resolve)=>{ 
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
      
      this.http.get(`${environment.appochard_baseurl}STU3/Appointment?patient=${patient}`,
      { headers: headers }).subscribe((res:any)=>{ 
        let ret = res.entry.map((el)=>{
          return el.resource;
        })
        resolve(ret);
      })   

    })
  }

  async getFutureAppointments(clientID,token,PatientID,MyChartAccountID){
    return new Promise<any>((resolve)=>{ 
      const patientBody ={
        "PatientID":PatientID,
        "PatientIDType":"EPI",
        "MyChartAccountID":MyChartAccountID,
        "MyChartAccountIDType":"External"
      } 
  
      const headers = new HttpHeaders()
      .set("Epic-Client-ID",clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
      
     
      this.http.post("https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2019/PatientAccess/External/GetFutureAppointments/Epic/Patient/Scheduling2019/GetFutureAppointments",
      patientBody,
      { headers: headers }).subscribe((res:any)=>{
          console.log(res);  
          resolve(res.Appointments);
      })   

    })
  }
}
