import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';  
import { Timestamp } from './Timestamp';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private datePipe:DatePipe) {
    console.log("v"+environment.appVersion, environment.environment);
    console.log("build date",Timestamp.stamp,this.datePipe.transform(new Date
      (parseInt(Timestamp.stamp)).toLocaleDateString(),"EEEE, MMMM d, y"));
  }
}
