import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { DoctorCardProfileComponent } from '../../components/doctor-card-profile/doctor-card-profile.component';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { HeaderCustomizeComponent } from '../../components/@header-customize/header-customize.component';
import { FooterCustomizeComponent } from '../../components/@footer-customize/footer-customize.component';
import { IonPopOverListComponent } from '../../components/ion-pop-over-list/ion-pop-over-list.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule, 
    IonicModule.forRoot({ 
     mode: 'md'
    }),
    ReactiveFormsModule,
    HttpClientModule ,
    SharedPipesModule.forRoot()
  ],
  declarations: [DoctorCardProfileComponent,
    IonPopOverListComponent,
  HeaderCustomizeComponent,FooterCustomizeComponent],
  exports: [DoctorCardProfileComponent,
    IonPopOverListComponent,
    HeaderCustomizeComponent,FooterCustomizeComponent]
})
export class SharedComponentsModule {
  static forRoot() {
    return {
        ngModule: SharedComponentsModule,
        providers: [],
    };
  }
 }
