import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizeShortenText'
})
export class SizeShortenTextPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    console.log(value);
    switch(value){
      case 'small':
        return "S";
        break;
      case 'extra small':
        return "XS";
        break;
      case 'medium':
        return "M";
        break;
      case 'large':
        return "L";
        break;
      case 'extra large':
        return "XL";
        break;
      default :
        return "medium";
        break;
    }
  }

}
