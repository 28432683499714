import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AlertController, LoadingController } from '@ionic/angular';
import { TenantModel } from 'src/app/models/tenant.model';
import { TenantService } from 'src/app/services/tenant.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import * as FHIR from "fhirclient";
import { AppointmentFhirModel } from 'src/app/models/appointmentfhir.model';
import { PatientModel } from 'src/app/models/patient.model';
import { Practitioner } from 'src/app/models/practitioner.model';
import { CustomHttpsService } from 'src/app/core/utils/custom-https.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProvidersService } from 'src/app/services/providers.service';
import { TextUtilService } from 'src/app/core/utils/text.util.service';
import { AppoinmentSearchService } from 'src/app/services/appoinment.search.service';
import { TeleConfig } from 'src/app/models/teleconfig.model';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-emr-ready-provider',
  templateUrl: './emr-ready-provider.page.html',
  styleUrls: ['./emr-ready-provider.page.scss'],
})
export class EmrReadyProviderPage implements OnInit {
  env= environment;
  clientID = environment.providerLaunchClientID; 
  chimeIframe:SafeResourceUrl;code="";
  token="";
  apptId = "";
  isProduction: boolean;
  isDebug: boolean;
  tenantModel:TenantModel={};
  isDesktop = false;
  teleConfig: TeleConfig  ;
  cTimers:any[]=[];  
  isMeetingRun = true;
  isAvailableSoon = false;
  isHideTimer = false;
  isMeetingEnd = false;
  timezone: string;
  aptNotes=[];
  
  serverDate:any;
  isBothOnline = false;
  MeetingID = ""; 
  devAppId = "";
  prodAppId = ""; 
  appoinmentMsgStatus:string;
  providerDeviceID="";

  patient:PatientModel;  
  appointmentFhirModel:AppointmentFhirModel;  
  practitioner:Practitioner;
  constructor(
    private alertController:AlertController,
    private tenantService:TenantService,
    private appointmentSearch :AppoinmentSearchService,
    private http:HttpClient,
    private patientService:PatientService,
    private providersService:ProvidersService,
    private cusHttp:CustomHttpsService,
    private loadingController:LoadingController,
    public sanitizer: DomSanitizer, ) { 

    this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime);  
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get('code');
    this.isProduction = environment.production;
    this.isDebug = environment.debug;
  }

  ngOnInit() {
    this.tenantModel.isNlpDefaultOn = 1;
    this.tenantModel.isRecordDefaultOn = 1;
    const orgId = localStorage.getItem("providerSideOrgID");
    if(orgId){
      this.tenantModel.TenantID = orgId;
    }else{
      this.alert("No Org ID declared.");
      return;
    }

    this.timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];  
    this.init();
    this.platformInit();  
  }

  platformInit(){
    setInterval(()=>{ 
      if(window.innerWidth < 991){
        //is mobile
        this.isDesktop = false; 
      }else{
        //is desktop
        this.isDesktop = true; 
      }
    },1000)
  }

  loading:any;
  async init() {
    this.initDevice();
    this.loading = await this.loadingController.create({ message: "Please wait ...."  });
    this.loading.present(); 
    await this.initToken(); 
    
    try{

      this.tenantModel = await this.tenantService.getWithConfig(this.tenantModel.TenantID);
      await this.initPatient();   
      await this.initAppointment();  
      await this.initPatientAppointment();
      await this.initPractitioner(); 
  
      if(this.isProduction || !this.isDebug){
        await this.initProdChime();
        await this.initExternalConnection(); 
      }else{
        await this.initDevChime();
      }
  
      const isAccess = await this.initMiddleWare();  
      if(!isAccess){
        console.log("asdsadas====",isAccess);
        const alert = await this.alertController.create({
          message: '1 device per account only',
          backdropDismiss:false
        });
        await alert.present();
      }  
      
      if(this.appointmentFhirModel){  
        const ScheduledAt = this.appointmentFhirModel.start;  
        const ScheduledEndAt = this.appointmentFhirModel.end; 
        
        setInterval(()=>{ 
          const ctimer = TextUtilService.counDownTimer(ScheduledAt);  
          this.cTimers = ctimer;   
   
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt);
          if(this.isBothOnline && !this.isMeetingEnd){
            this.isMeetingRun =true;
            this.isMeetingEnd = false;
            this.isAvailableSoon = false; 
            this.isHideTimer = false; 
            // Timer of the meeting
            this.cTimers = TextUtilService.counDownTimer(this.serverDate,true);  
            this.appoinmentMsgStatus = "Appointment Has Already Begun"; 
          }else{ 
            if((dateTimeDif[0].number <= -1 && dateTimeDif[1].number <= -1 && 
              dateTimeDifEnd[2].number >= 0 && dateTimeDif[2].number <= 0) && !this.isBothOnline  ){ 
              //  Time for meeting
              this.isHideTimer = true;  
              this.isMeetingRun =true;
              this.isMeetingEnd = false;
              this.isAvailableSoon = false; 
              // Timer of the meeting
              this.cTimers = TextUtilService.counDownTimer(ScheduledAt,true);     
              this.appoinmentMsgStatus = "Your visit will start soon"; 
            }else if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
              dateTimeDifEnd[2].number < 0){
                //  Meeting End 
                this.isMeetingEnd = true;
                this.isHideTimer = true;
                this.isMeetingRun = false;
                this.isAvailableSoon = false;    
                this.appoinmentMsgStatus = "This session has ended";  
            }else if(parseInt(ctimer[0].number) > 0 || parseInt(ctimer[1].number) > 0 ||
              parseInt(ctimer[2].number) > 30){ 
                // Available soon 
              this.isAvailableSoon = true;
              this.isMeetingRun = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false; 
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }else{
              // 30 minutes before call
                // Prepare for the meeting
              this.isMeetingRun = true;
              this.isAvailableSoon = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false;
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }   
          }
        },1000);
         
      }else{
        this.noAppointment();
      }       
      setTimeout(() => {   
        this.loading.dismiss();
        // this.askDevicePermission();
      }, 6000); 

    }catch(e){
      this.alert(e.message);
      this.loading.dismiss();
    }
  }

  initAppointment() {
    return new Promise<any>(async(resolve)=>{ 
      const appsearchs = await this.appointmentSearch.get(this.clientID,this.token,this.patient.id);
      console.log("initAppointment",appsearchs);
      if(this.isDebug){
        this.appointmentFhirModel = appsearchs.find((el)=>{   
          const ScheduledAt = el.start;  
          const ScheduledEndAt = el.end;     
    
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
    
          console.log("start",dateTimeDif);
          console.log("end",dateTimeDifEnd);
          if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
            dateTimeDifEnd[2].number < 0){
            //  Meeting End  
          }else{
            return true;
          } 
        })  
      }else{
        this.appointmentFhirModel = appsearchs.find((el)=>{  
          return el.id = this.apptId;
        }) 
      } 

      if(!this.appointmentFhirModel){
        this.noAppointment();
        return;
      } 

      if(this.appointmentFhirModel.start){ 
        this.devAppId = this.patient.id+new Date(this.appointmentFhirModel.start).getTime() +"-"+this.tenantModel.TenantID;
      }else{
        this.noAppointment();
        return;
      } 

      console.log("initAppointment",this.appointmentFhirModel)
      resolve({});
    })
  }

  initPatient() {
    return new Promise<any>((resolve,reject)=>{ 
      FHIR.oauth2, 
      FHIR.oauth2.ready()
      .then(client => client.request(`Patient/${client.patient.id}`))
      .then((el:PatientModel)=>{ 
        console.log(el);
        this.patient= el;
        resolve({});
      })
      .catch(async (err)=>{
        console.log(err); 
        const alert = await this.alertController.create({
          message: 'Session expired, please go back to HyperSpace.',
          backdropDismiss:false
        });
        reject({})}
      );
    })
  }
  
  initExternalConnection() {   
    return new Promise<any>((resolve,reject)=>{   
      FHIR.oauth2.ready()
      .then(async (client) => {
        
          const tokenResponse = client.state.tokenResponse;  
          
          console.log("initExternalConnection",this.appointmentFhirModel,this.patient,this.practitioner);
          // Practitioner===========
          var ConferenceID= this.appointmentFhirModel.identifier[0].value;
          var ExternalID= this.practitioner.identifier.find((o)=>{ 
            if(o.type){
              return o.type.text.toLowerCase() === "EXTERNAL".toLowerCase()
            }}).value;
          var ExternalIDType= 1;
          var VendorName= "Edera Video Visit";
          var ConnectionStatus= 1;
           
           
          var token = client.state.tokenResponse.access_token; 
          var headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.providerLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
   
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          })  
          
          // Patient===========
          console.log("initExternalConnection",this.appointmentFhirModel,this.patient,this.practitioner);
          var ConferenceID= this.appointmentFhirModel.identifier[0].value;
          console.log("initExternalConnection",ConferenceID);
          var ExternalID= this.patient.identifier.find((o)=>{
            if(o.type){
              return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}
            }).value;
          console.log("initExternalConnection",ExternalID);
          var ExternalIDType= 2;
          var VendorName= "Edera Video Visit";
          var ConnectionStatus= 1;
           
          var token = client.state.tokenResponse.access_token; 
          var headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
   
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          })  
          
          this.http.get(`${environment.appochard_baseurl}STU3/Encounter/${tokenResponse.encounter}`,
          { headers: headers }).subscribe((res:any)=>{
              console.log(res);  
          })   

          await new Promise<any>((resolve)=>{   
            this.http.get(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2018/Telehealth/External/TelemedicineConfiguration`,
            { headers: headers }).subscribe((res:TeleConfig)=>{
                console.log(res);  
                this.teleConfig = res;
                resolve({});
            })   
          }) 
          resolve({});
      }) 
      .catch(()=>{reject({})}); 
    });
  }

  
  async initPractitioner() {
    const reference = this.appointmentFhirModel.participant.find((el)=>{return el.actor.reference.toLowerCase().includes("practitioner");}).actor.reference;
    
    console.log(`initPractitioner`,reference);
    this.practitioner = await this.providersService.getPractitioner(this.clientID,this.token,reference);
    console.log(`initPractitioner`,this.practitioner);
  }

  initPatientAppointment() {
    return new Promise<any>(async (resolve)=>{ 
      const patientID  =this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value;
      await this.patientService.getPatientAppointments(this.clientID,this.token,patientID); 
      const patientApts =  await this.patientService.getPatientAppointments(this.clientID,this.token,patientID);
      const currentPatientApts = patientApts.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhirModel.identifier[0].value;})  && el.VisitTypeIDs.find((er)=>{return er.ID.toLowerCase().includes("238")})  });
      console.log("currentPatientApts",currentPatientApts);
      if(!currentPatientApts){this.noAppointment();return;}
      this.aptNotes = currentPatientApts.AppointmentNotes;
      resolve({});
    })
  }
  
  async initToken() { 
    return new Promise<any>(async (resolve,reject)=>{ 
      
      FHIR.oauth2,
      FHIR.oauth2.ready()
      .then(async client => { 
  
        this.prodAppId = client.state.tokenResponse.appointment+ "-" +this.tenantModel.TenantID;
        this.token = client.state.tokenResponse.access_token; 

        if(!client.patient.id){ 
          try{ 

            const smartkey = sessionStorage.getItem("SMART_KEY").split('"')[1];
            const clientId= environment.providerLaunchClientID;  
            var headers = new Headers(); 
            var requestOptions:any = {
              method: 'GET',
              headers: headers,
              redirect: 'follow'
            };
        
            let app = JSON.parse(sessionStorage.getItem(smartkey));  
            if(!app.tokenResponse.access_token){
              var urlencoded = new URLSearchParams();
              urlencoded.append("code", this.code);
              urlencoded.append("grant_type", "authorization_code");
              urlencoded.append("redirect_uri", app.redirectUri);
              urlencoded.append("client_id", clientId);
      
              var requestOptions:any = {
                method: 'POST',
                headers: headers,
                body: urlencoded,
                redirect: 'follow'
              };
      
              fetch("https://apporchard.epic.com/interconnect-aocurprd-oauth/oauth2/token", requestOptions)
              .then(response => response.json())
              .then(result => { 
                var appID = result.appointment +"-"+this.tenantModel.TenantID; 
                app = Object.assign(app,{
                  tokenResponse:result
                }) 
                sessionStorage.setItem(smartkey,JSON.stringify(app));
                this.token = app.tokenResponse.access_token;
                this.prodAppId =  appID ;
                resolve({});
              })
              .catch(error =>{ reject({})});
            }else{
              resolve({})
            }
  
          }catch(err){
            console.log(err);
            reject({});
            const alert = await this.alertController.create({
              message: 'Session expired, please go back to HyperSpace.',
              backdropDismiss:false
            });
          }
        }else{
          resolve({});
        }
      })
    })
  } 
  
  initDevice() {
    this.providerDeviceID= localStorage.getItem("provider-uid");
    if(!this.providerDeviceID){
      this.providerDeviceID = uuidv4();
      localStorage.setItem("provider-uid",this.providerDeviceID);
    } 
  } 

  
  initMiddleWare() {
    return new Promise<any>((resolve)=>{  
      this.cusHttp.post(`chimeApi/app-middleware?iam=provider&computerID=${this.providerDeviceID}&MeetingID=${this.MeetingID}`,{})
      .subscribe((el:any)=>{   
        resolve(el.isAccess);
      }); 
    })
  }

  initProdChime() {

    return new Promise<any>((resolve)=>{  
      FHIR.oauth2.ready()
      .then(async (client:any) =>{  
        const body  = {
          Provider: {
            ID:this.practitioner.id
          },
          PatientID:this.patient.id,
          TenantID:this.tenantModel.TenantID,
          AppointmentID:this.prodAppId,
          AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
          PatientName:this.patient.name[0].text, 
          ScheduledAt:this.appointmentFhirModel.start,
          ScheduledEndAt:this.appointmentFhirModel.end,
          FirstName:this.patient.name[0].given[0],
          LastName:this.patient.name[0].family,
          Gender:this.patient.gender,
          BirthDate:this.patient.birthDate
        }
        await new Promise<any>((resolve)=>{
          this.cusHttp.post(`chimeApi/createAppointment`,body)
          .subscribe((el:any)=>{resolve({})}); 
        })

        this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.prodAppId}&TenantID=${this.tenantModel.TenantID}`,{})
        .subscribe((el:any)=>{ 
          this.MeetingID = el.MeetingID; 
          this.prodKeepMeAlive();
          resolve({}); 
          this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.practitioner.name[0].text+"&appointmentID="+this.prodAppId+"&iam=provider"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);      
        });
      })  
    })
  }

  prodKeepMeAlive() { 
    const inter = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.prodAppId}&MeetingID=${this.MeetingID}&iam=provider&computerID=${this.providerDeviceID}`,{})
      .subscribe((el:any)=>{  
        console.log("keepmealive",el);
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.prodKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => { 
              clearInterval(inter);
              await this.initProdChime();
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }

  initDevChime() {
    console.log(`initDevChime`);
    return new Promise<any>(async (resolve)=>{  
      const body  = {
        Provider: {
          ID:this.practitioner.id
        },
        PatientID:this.patient.id,
        TenantID:this.tenantModel.TenantID,
        AppointmentID:this.devAppId,
        AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
        PatientName:this.patient.name[0].text, 
        ScheduledAt:this.appointmentFhirModel.start,
        ScheduledEndAt:this.appointmentFhirModel.end,
        FirstName:this.patient.name[0].given[0],
        LastName:this.patient.name[0].family,
        Gender:this.patient.gender,
        BirthDate:this.patient.birthDate
      }
      await new Promise<any>((resolve)=>{
        this.cusHttp.post(`chimeApi/createAppointment`,body)
        .subscribe((el:any)=>{resolve({})}); 
      })


      this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.devAppId}&TenantID=${this.tenantModel.TenantID}`,{})
      .subscribe((el:any)=>{ 
        this.MeetingID = el.MeetingID; 
        this.devKeepMeAlive();
        resolve({}); 
        this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.practitioner.name[0].text+"&appointmentID="+this.devAppId+"&iam=provider"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);       
      });

    })
  }

  devKeepMeAlive() { 
    const inter  = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.devAppId}&MeetingID=${this.MeetingID}&iam=provider&computerID=${this.providerDeviceID}`,{})
      .subscribe((el:any)=>{ 
        console.log("keepmealive",el);
         
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.devKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => {
              clearInterval(inter);
              await this.initDevChime();
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }
  







  
  async onMyChartError(){ 
    this.loading.dismiss();
    const alert = await this.alertController.create({
      message: 'Session expired, please go back to HyperSpace.',
      backdropDismiss:false
    });
  
    await alert.present(); 
  }

  async alert(message){  
    if(message=='' || !message){message = 'Session expired, please go back to MyChart.'}
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false
    }); 
    await alert.present(); 
  } 

  async noAppointment() {
    const alert = await this.alertController.create({
      message: 'Have no appointment scheduled.',
      backdropDismiss:false
    });
    await alert.present();  
    await this.loading.dismiss();
  }

  
}
