import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TenantModel } from '../models/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http:HttpClient,
    private alertController:AlertController ) { }

  getWithConfig(TenantID){ 
    
    return new Promise<TenantModel>((resolve,reject)=>{    
      const headers = new HttpHeaders() 
      .set('content-type', 'application/json') ;
       
      this.http.get(`${environment.baseURL}tenant/tenant-config?TenantID=${TenantID}`,
      { headers: headers }).subscribe(async (res:any)=>{  
        if(res.success){ 
          resolve(res.data); 
        }else{  
          reject({message:res.message}); 
        }
      })   
    });

  }


}
