import { Component, OnInit, ViewChild } from '@angular/core';
import { TextUtilService } from 'src/app/core/utils/text.util.service';
import { PatientModel } from 'src/app/models/patient.model';
import * as FHIR from "fhirclient";
import { AlertController, IonSlides, LoadingController, ModalController } from '@ionic/angular';
import { AppointmentModel } from 'src/app/models/appointment.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastMessageService } from 'src/app/core/utils/toast-message.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router'; 
import {  Dosage, Entry, MedicationStatementBundle } from 'src/app/models/medication-statement-bundle.model';
import { DatePipe } from '@angular/common';
import { Content, DocumentReference, DocumentReferenceEncounter, Subject } from 'src/app/models/documentreference.model'; 
import { PastVisitModel } from 'src/app/models/past-visit.model'; 
import { ViewNotesComponent } from './components/view-notes/view-notes.component'; 
import { ViewNote } from 'src/app/models/view-note.model';
import { AllergyDisplayModel } from 'src/app/models/allergy.display.model';
import { AllergyModel } from 'src/app/models/allergy.model';
import { ConditionModel } from 'src/app/models/condition.model'; 
import { CustomHttpsService } from 'src/app/core/utils/custom-https.service';
import { PreventiveCareModel } from 'src/app/models/preventivecare.model';
import { environment } from 'src/environments/environment';
import { AppointmentFhirModel } from 'src/app/models/appointmentfhir.model';
import { Encounter } from 'src/app/models/encounter.model';  
import { UploadtostService } from 'src/app/core/utils/uploadtost.service';
import { PatientFile } from 'src/app/models/patient-file.model'; 
import { PatientAppointment } from 'src/app/models/patientappoinment.model'; 
import { AppoinmentSearchService } from 'src/app/services/appoinment.search.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { Practitioner } from 'src/app/models/practitioner.model';
import { LocationModel } from 'src/app/models/location.model';
import { LocationService } from 'src/app/services/location.service'; 
import { TenantModel } from 'src/app/models/tenant.model';
import { TenantService } from 'src/app/services/tenant.service'; 
const {v4 :uuidv4} = require('uuid');
 
@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.page.html',
  styleUrls: ['./meeting.page.scss'],
})
export class MeetingPage implements OnInit {   
  env= environment;
  clientID = environment.patientLaunchClientID;
  token=""; 
  aptNotes=[];
  @ViewChild("slideMessage") slideMessage:IonSlides;
  isMax:boolean = false;
  isSlideShow = true;
  patient:PatientModel;
  practitioner:Practitioner; 
  location:LocationModel;
  chimeIframe:SafeResourceUrl;
  patientFiles:PatientFile[]=[];
  appointments:AppointmentFhirModel[]|any = []; 
  appointmentAppOrchard:AppointmentModel; 
  appointmentFhirModel:AppointmentFhirModel;
  medicines :Entry[]=[];
  reminders:string[]=[];
  cTimers:any[]=[]; 
  pastEncounters:DocumentReference[]=[];
  appoinmentMsgStatus:string;
  tenantModel:TenantModel={};
  interactiveMessage:string; 
  allergies:AllergyDisplayModel[] =[] ;
  conditions:ConditionModel[] = [];
  interactiveMessages:string[]= new Array(); 
  preventivecares:PreventiveCareModel[]=[];

  isDesktop = false;
  isProduction: boolean = false;
  isDebug: boolean;
  code:string;
  
  isMeetingRun = true;
  isAvailableSoon = false;
  isHideTimer = false;
  isMeetingEnd = false;
  timezone: string; 

  serverDate:any;
  isBothOnline = false;
  devAppId = ""; 
  prodAppId = ""; 
  MeetingID = "";
  appointmentID = ""; 
  patientDeviceID = "";

  platformInit(){
    setInterval(()=>{ 
      // if(window.innerWidth < 1026){
      if(window.innerWidth < 991){
        //is mobile
        this.isDesktop = false; 
      }else{
        //is desktop
        this.isDesktop = true; 
      }
    },1000)
  }
  
  constructor(private http:HttpClient,
    private toast :ToastMessageService, 
    public sanitizer: DomSanitizer,
    private providersService:ProvidersService,
    private patientService:PatientService,
    private alertController:AlertController,
    private appointmentSearch :AppoinmentSearchService,
    private tenantService:TenantService,
    private cusHttp:CustomHttpsService,
    private modalController:ModalController,
    private loadingController:LoadingController) { 
      this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime); 
      const urlParams = new URLSearchParams(window.location.search);
      this.code = urlParams.get('code');
      this.isProduction = environment.production;
      this.isDebug = environment.debug;
  }

  async ngOnInit() {    
    document.getElementById("support").style.display = "none";
    this.tenantModel.isNlpDefaultOn = 1;
    this.tenantModel.isRecordDefaultOn = 1;
    const orgId = localStorage.getItem("patientSideOrgID");
    if(orgId){
      this.tenantModel.TenantID = orgId;
    }else{
      this.alert("No Org ID declared.");
      return;
    }
    
    this.initDevice();  
    this.timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];   
    this.init(); 
    this.platformInit(); 
  } 

  initMiddleWare() {
    return new Promise<any>((resolve)=>{  
      this.cusHttp.post(`chimeApi/app-middleware?iam=patient&computerID=${this.patientDeviceID}&MeetingID=${this.MeetingID}`,{})
      .subscribe((el:any)=>{   
        resolve(el.isAccess);
      }); 
    })
  }
 
  loading :any;
  async init() {
    console.log(`init`);
    this.loading = await this.loadingController.create({ message: "Please wait ...."  });
    this.loading.present(); 
    await this.initToken();

    try{
      this.tenantModel = await this.tenantService.getWithConfig(this.tenantModel.TenantID); 
      await this.initPatient(); 
      await this.initAppointment(); 
      await this.initPatientAppointment();
      await this.initPractitioner();  
  
      if(this.isProduction || !this.isDebug){
        await this.initProdChime();
        await this.initExternalConnection(); 
      }
      else{
        await this.initDevChime();
      }
      
      const isAccess = await this.initMiddleWare(); 
      console.log("asdsadas",isAccess);
      if(!isAccess){
        console.log("asdsadas====",isAccess);
        const alert = await this.alertController.create({
          message: '1 device per account only',
          backdropDismiss:false
        });
        await alert.present();
      } 
      
      this.getPatientFiles();  
      this.initMedication(); 
      this.initPreventiveCare();
      this.initCondition(); 
      await this.initPastVisitNote();   
      await this.initAllergies();
      this.initPatientDemoGraphics();
 
      if(this.appointmentFhirModel){  
        const ScheduledAt = this.appointmentFhirModel.start;  
        const ScheduledEndAt = this.appointmentFhirModel.end; 
        
        setInterval(()=>{ 
          const ctimer = TextUtilService.counDownTimer(ScheduledAt);  
          this.cTimers = ctimer;   
  
          const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
          const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
          if(this.isBothOnline && !this.isMeetingEnd){
            this.isMeetingRun =true;
            this.isMeetingEnd = false;
            this.isAvailableSoon = false; 
            this.isHideTimer = false; 
            // Timer of the meeting
            this.cTimers = TextUtilService.counDownTimer(this.serverDate,true);  
            this.appoinmentMsgStatus = "Appointment Has Already Begun"; 
          }else{
            // if(dateTimeDif[0].number <= -1 && dateTimeDif[1].number <= -1 && 
            //   dateTimeDifEnd[2].number >= 0 && dateTimeDif[2].number <= 0   ){ 
            //   //  Time for meeting
            //   this.isHideTimer = false;  
            //   this.isMeetingRun =true;
            //   this.isMeetingEnd = false;
            //   this.isAvailableSoon = false; 
            //   // Timer of the meeting
            //   this.cTimers = TextUtilService.counDownTimer(ScheduledAt,true);     
            //   this.appoinmentMsgStatus = "Appointment Has Already Begun"; 
            // } 
            if((dateTimeDif[0].number <= -1 && dateTimeDif[1].number <= -1 && 
              dateTimeDifEnd[2].number >= 0 && dateTimeDif[2].number <= 0) && !this.isBothOnline  ){ 
              //  Time for meeting
              this.isHideTimer = true;  
              this.isMeetingRun =true;
              this.isMeetingEnd = false;
              this.isAvailableSoon = false; 
              // Timer of the meeting
              this.cTimers = TextUtilService.counDownTimer(ScheduledAt,true);     
              this.appoinmentMsgStatus = "Your visit will start soon"; 
            }else if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
              dateTimeDifEnd[2].number < 0){
              //  Meeting End 
              this.isMeetingEnd = true;
              this.isHideTimer = true;
              this.isMeetingRun = false;
              this.isAvailableSoon = false;    
              this.appoinmentMsgStatus = "This session has ended";  
            }else if(parseInt(ctimer[0].number) > 0 || parseInt(ctimer[1].number) > 0 ||
              parseInt(ctimer[2].number) > 30){ 
                // Available soon 
              this.isAvailableSoon = true;
              this.isMeetingRun = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false; 
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }else{
              // 30 minutes before call
                // Prepare for the meeting
              this.isMeetingRun = true;
              this.isAvailableSoon = false;
              this.isHideTimer = false;
              this.isMeetingEnd = false;
              this.appoinmentMsgStatus = "Appointment Will Start In";
            }   
          }
        },1000); 
        this.initInteractiveMessage(); 
      }else{   
        this.noAppointment();
      }       
      
      setTimeout(() => {   
        this.loading.dismiss(); 
      }, 6000); 
      
    }catch(e){ 
      this.alert(e.message);
      this.loading.dismiss();
    }  
  }  

  async initPatientDemoGraphics() { 
    const PatientID= this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value; 
    const MyChartAccountID =	this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}}).value;  
    const patientDemoGraphics = await this.patientService.getPatientDemographics(this.clientID,this.token,PatientID,MyChartAccountID); 
    this.patientService.saveCPatientDemoGraphics(this.patient.id,patientDemoGraphics);
  }

  askDevicePermission() {
    navigator.mediaDevices.getUserMedia({video: true, audio: true}).then( stream => { 
    }).catch( err => { 
      this.toast.presentToast("Camera and microphone requires to be permitted");
    });
  }

  async initToken() { 
    return new Promise<any>(async (resolve,reject)=>{   
      
      FHIR.oauth2.ready()
      .then(async client => { 
        this.appointmentID = client.state.tokenResponse.appointment;
        this.prodAppId = client.state.tokenResponse.appointment+ "-" +this.tenantModel.TenantID;
        this.token = client.state.tokenResponse.access_token; 
        console.log("initToken",client);
        if(!client.patient.id){ 
          try{  
            const smartkey = sessionStorage.getItem("SMART_KEY").split('"')[1];
            const clientId= environment.providerLaunchClientID;  
            var headers = new Headers(); 
            var requestOptions:any = {
              method: 'GET',
              headers: headers,
              redirect: 'follow'
            };
        
            let app = JSON.parse(sessionStorage.getItem(smartkey)); 
            if(!app.tokenResponse.access_token){
              var urlencoded = new URLSearchParams();
              urlencoded.append("code", this.code);
              urlencoded.append("grant_type", "authorization_code");
              urlencoded.append("redirect_uri", app.redirectUri);
              urlencoded.append("client_id", clientId);
      
              var requestOptions:any = {
                method: 'POST',
                headers: headers,
                body: urlencoded,
                redirect: 'follow'
              };
      
              fetch("https://apporchard.epic.com/interconnect-aocurprd-oauth/oauth2/token", requestOptions)
              .then(response => response.json())
              .then(result => { 
                var appID = result.appointment +"-"+this.tenantModel.TenantID; 
                app = Object.assign(app,{
                  tokenResponse:result
                }) 
                sessionStorage.setItem(smartkey,JSON.stringify(app));
                this.token = app.tokenResponse.access_token;
                this.prodAppId =  appID ;
                resolve({});
              })
              .catch(error =>{ reject({})});
            }else{
              resolve({})
            } 
          }catch(err){
            console.log(err);
            reject({});
            const alert= await this.alertController.create({
              message: 'Session expired, please go back to HyperSpace.',
              backdropDismiss:false
            });
            await alert.present();
          }
        }else{
          resolve({});
        }
      })
    })
  }

  initExternalConnection() {   
    return new Promise<any>((resolve,reject)=>{   
      FHIR.oauth2.ready()
      .then(async (client) => {
        
          const tokenResponse = client.state.tokenResponse; 
          const ConferenceID= this.appointmentFhirModel.identifier[0].value;
          const ExternalID= this.patient.identifier.find((o)=>{     if(o.type){
            return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}
          }).value;
          const ExternalIDType= 2;
          const VendorName= "Edera Video Visit";
          const ConnectionStatus= 1;
           
          const token = tokenResponse.access_token;
          const headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
  
          console.log(`initExternalConnection`,tokenResponse,`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`);
          await new Promise<any>((resolve)=>{   
            this.http.get(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2018/Telehealth/ContextLinking/SetExternalConnectionStatus?ConferenceID=${ConferenceID}&ExternalID=${ExternalID}&ExternalIDType=${ExternalIDType}&VendorName=${VendorName}&ConnectionStatus=${ConnectionStatus}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res);  
                resolve({});
            })  
          })   
          this.http.get(`${environment.appochard_baseurl}STU3/Encounter/${tokenResponse.encounter}`,
          { headers: headers }).subscribe((res:any)=>{
              console.log(res);   
          })   
          resolve({});
      }) 
      .catch(()=>{reject({})}); 
    });
  }

  initPreventiveCare() {
    console.log(`initPreventiveCare`);
    return new Promise<any>((resolve,reject)=>{ 
      FHIR.oauth2.ready()
      .then((client) => {  
        const token = client.state.tokenResponse.access_token;
        const PatientID= this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "external".toLowerCase()}}).value;
        const PatientIDType ="external"; 
        const MyChartAccountID =	this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}}).value;
        const MyChartAccountIDType ="external"; 

        const headersXml = new HttpHeaders()
        .set("Epic-Client-ID", environment.patientLaunchClientID) 
        .set('Authorization', "Bearer "+ token) 
        .set('content-type', 'application/xml')   
        const xml =`<GetHealthAdvisories xmlns="urn:epicsystems-com:PatientAccessMobile.2017.Services" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <!--string occurs:0,1-->
        <PatientID xsi:nil="false">${PatientID}</PatientID>
        <!--string occurs:0,1-->
        <PatientIDType xsi:nil="false">${PatientIDType}</PatientIDType>
        <!--string occurs:0,1-->
        <MyChartAccountID xsi:nil="false">${MyChartAccountID}</MyChartAccountID>
        <!--string occurs:0,1-->
        <MyChartAccountIDType xsi:nil="false">${MyChartAccountIDType}</MyChartAccountIDType>
      </GetHealthAdvisories>`;
        this.http.post(`https://apporchard.epic.com/interconnect-aocurprd-oauth/wcf/Epic.PatientAccessMobile.Services/PatientAccessMobile.svc/rest_2017/ClinicalServices2017/HealthAdvisories`, 
        xml,{ headers: headersXml }).subscribe((res:any)=>{ 
          this.preventivecares = res.GetHealthAdvisoriesResult;
            resolve({});
        })    
      }) 
      .catch(()=>{reject({})}); 
    })
  }

  initCondition() {
    console.log(`initCondition`);  
    return new Promise<any>((resolve,reject)=>{
      FHIR.oauth2.ready()
      .then(client => client.request(`Condition?patient=${client.patient.id}`))
      .then((res:any)=>{
        for(const condition of res.entry){
          if(condition.resource.resourceType  != "OperationOutcome"){
            this.conditions.push(condition.resource); 
          }
        }
        resolve({});
      })
      .catch(()=>{   
        reject({});
      });
    })
  }

  initAllergies() {
    console.log(`initAllergies`); 
    return new Promise<any>((resolve,reject)=>{
      FHIR.oauth2.ready()
      .then(client => client.request(`AllergyIntolerance?patient=${client.patient.id}`))
      .then((res:any)=>{ 
        console.log(res.entry);
        resolve({});
        for(let entry of res.entry){
          if(entry.resource.resourceType != "OperationOutcome"){ 
            const allergy:AllergyModel = entry.resource;
            console.log({
              title:allergy.code.text 
            }) 
            this.allergies.push({
              title:allergy.code.text,
              addedAt:allergy.onsetPeriod.start,
              reactions:allergy.reaction? allergy.reaction.map((ek)=>{return ek.description}).join(', '):''
            });
          }
        } 
      })
      .catch(()=>{  
        reject({});
      });
    })
  }

  initInteractiveMessage() {  
    this.interactiveMessages = [ 
      `How are you today, ${this.patient.name[0].text}?`,
      "Please follow the medicine reminder.",
      "Prepare all the documents we need before our meeting.",
      // `Looking forward to our ${this.appointment.VisitType.Name}, ${this.patient.name[0].text}!`
    ]; 
    for(let medicine of this.medicines){
      this.interactiveMessages.push(`Don't forget to take your ${medicine.resource.medicationReference.display} medicine.`);
    }
    console.log(`this.reminders`);
    console.log(this.reminders);
    for(let reminder of this.reminders){
      this.interactiveMessages.push(reminder);
    }
    if(this.appointments.length > 1){
      const pipe = new DatePipe('en-US');  
      // const appointmentsI = this.appointments.findIndex((el)=>{return el.ContactIDs.find((er)=>{return er.Type.toLowerCase() == "asn" }).ID == this.appointmentFhirModel.ContactIDs.find((er)=>{return er.Type.toLowerCase() == "asn" }).ID  }) +1;
      // if(this.appointments.length > appointmentsI){ 
      //   const datetime = new Date(this.appointments[appointmentsI].ArrivalTime).getTime();
      //   const fomatted = pipe.transform(datetime, 'EEE, M/dd/yyyy, h:mm a');
      //   this.interactiveMessages.push(`Your next appointment is on ${fomatted} ${this.timezone}`);
      // }
    }
    this.interactiveMessages.sort(() => Math.random() - 0.5);
    // this.interactiveMessage = this.interactiveMessages[Math.floor(Math.random()*(this.interactiveMessages.length-1-0) + 0)];
    let slideIndex = 0;
    setInterval(()=>{
      if(slideIndex < this.interactiveMessages.length-1){
        slideIndex++;
        this.slideMessage.slideNext();
      }else{ 
        this.slideMessage.slideTo(0);
        slideIndex = 0;
      } 
    },15000);
  }
 
  initPastVisitNote() {
    return new Promise<any>((resolve,reject)=>{  
        FHIR.oauth2.ready()
        .then((client) => {
            console.log(`initPastVisitNote`); 
            console.log(client.patient.id); 
            console.log(client ); 
            const token = client.state.tokenResponse.access_token;
            const clientID = client.patient.id;
            console.log(clientID); 
            const headers = new HttpHeaders()
            .set("Epic-Client-ID", environment.patientLaunchClientID) 
            .set('Authorization', "Bearer "+ token) 
            .set('content-type', 'application/json');

            this.http.get(`${environment.appochard_baseurl}R4/DocumentReference?patient=${clientID}`,
            { headers: headers }).subscribe((res:any)=>{
                console.log(res); 
                console.log(this.pastEncounters);
                this.pastEncounters= res.entry.map((el)=>{
                  return el.resource;
                })
                this.pastEncounters=this.pastEncounters.filter((el)=>{
                  return el.resourceType.toLowerCase() === "DocumentReference".toLowerCase();
                })
                console.log(this.pastEncounters); 
            })
            resolve({}); 
        }) 
        .catch(()=>{reject({})}); 
    });
  }
   
  async onViewNote(pastEncounter:DocumentReference){ 
    const modal = await this.modalController.create({
      component: ViewNotesComponent,  
      componentProps:{
        pastEncounter:pastEncounter
      }
    });
    await modal.present();
  }


  initAppointment() {
    return new Promise<any>(async(resolve)=>{ 
      const appsearchs = await this.appointmentSearch.get(this.clientID, this.token,this.patient.id);
      this.appointments = appsearchs;
      console.log("initAppointment",appsearchs);
      // if(this.isDebug){
      //   this.appointmentFhirModel = appsearchs.find((el)=>{   
      //     const ScheduledAt = el.start;  
      //     const ScheduledEndAt = el.end;     
    
      //     const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
      //     const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
    
      //     console.log("start",dateTimeDif,ScheduledAt);
      //     console.log("end",dateTimeDifEnd,ScheduledEndAt);
          
      //     if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
      //       dateTimeDifEnd[2].number < 0){
      //       //  Meeting End  
      //     }else{
      //       return true;
      //     } 
      //   })  
      // }else{
      //   this.appointmentFhirModel = appsearchs.find((el)=>{  
      //     return el.id = this.appointmentID;
      //   }) 
      // } 
      
      this.appointmentFhirModel = appsearchs.find((el)=>{   
        const ScheduledAt = el.start;  
        const ScheduledEndAt = el.end;     
  
        const dateTimeDif = TextUtilService.getDateTimeDif(ScheduledAt);
        const dateTimeDifEnd = TextUtilService.getDateTimeDif(ScheduledEndAt); 
  
        console.log("start",dateTimeDif,ScheduledAt);
        console.log("end",dateTimeDifEnd,ScheduledEndAt);
        
        if(dateTimeDif[0].number <= 0 && dateTimeDif[1].number <= 0 && 
          dateTimeDifEnd[2].number < 0){
          //  Meeting End  
        }else{
          return true;
        } 
      })  

      if(!this.appointmentFhirModel){
        this.noAppointment();
        return;
      }

      if(this.appointmentFhirModel.start){ 
        this.devAppId = this.patient.id+new Date(this.appointmentFhirModel.start).getTime() +"-"+this.tenantModel.TenantID;
      }else{
        this.noAppointment();
        return;
      } 

      this.appointments = this.appointments.map((el)=>{ 
        el.IsShow = (new Date(el.start).getTime()-new Date(this.appointmentFhirModel.start).getTime()) > 0;
        return el;
      });   
      
      console.log("initAppointment",this.appointments,this.appointmentFhirModel);
      resolve({});
    })
  }
 
  initPatientAppointment() {
    return new Promise<any>(async (resolve)=>{ 
    const patientID  =this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value;
    // await this.patientService.getPatientAppointments(this.clientID,this.token,patientID); 
    const patientApts =  await this.patientService.getPatientAppointments(this.clientID,this.token,patientID);
    console.log("patientApts",patientApts);
    const currentPatientApts = patientApts.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhirModel.identifier[0].value;})  && el.VisitTypeIDs.find((er)=>{return er.ID.toLowerCase().includes("238")})  });
    console.log("currentPatientApts",currentPatientApts);
    
    const PatientID= this.patient.identifier.find((o)=>{if(o.type){ return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value; 
    const MyChartAccountID = this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "WPRINTERNAL".toLowerCase()}}).value; 
    const appSearchs = await this.appointmentSearch.getFutureAppointments(this.clientID,this.token,PatientID,MyChartAccountID);
    this.appointmentAppOrchard = appSearchs.find((el)=>{return el.ContactIDs.find((er)=>{return er.ID == this.appointmentFhirModel.identifier[0].value;})});
    
    if(!currentPatientApts){this.noAppointment();return;}
    this.aptNotes = currentPatientApts.AppointmentNotes;
    console.log("currentPatientApts",currentPatientApts,this.appointmentAppOrchard);
      resolve({});
    })
  }

  async initPractitioner() {
    const reference = this.appointmentFhirModel.participant.find((el)=>{return el.actor.reference.toLowerCase().includes("practitioner");}).actor.reference;
    
    console.log(`initPractitioner`,reference);
    this.practitioner = await this.providersService.getPractitioner(this.clientID,this.token,reference);
    console.log(`initPractitioner`,this.practitioner);
  }


  initPatient() {
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2,
      FHIR.oauth2.ready()
      .then(client => client.request(`Patient/${client.patient.id}`))
      .then((el:PatientModel)=>{ 
        console.log(el);
        this.patient= el; 
        resolve({}); 
      })
      .catch(()=>{ 
        this.onMyChartError(); 
        reject({})}
      ); 
    }) 
  }
  
  initMedication(){ 
    console.log('initMedication'); 
    return new Promise<any>((resolve,reject)=>{  
      FHIR.oauth2.ready()
      .then(client => client.request(`MedicationStatement?Patient=${client.patient.id}`))
      .then((el:MedicationStatementBundle)=>{ 
        const entries:any = el.entry.filter((o:Entry)=>{
          return o.resource.resourceType === "MedicationStatement";
        });
        console.log(entries);
        this.medicines = entries;
        entries.forEach((el:Entry)=>{
          el.resource.dosage.forEach((ek:Dosage)=>{
            this.reminders.push(ek.text);
          });
        }); 
        resolve({});
      })
      .catch((err)=>{
        console.log(err); 
        reject({})}
      );
    })
  }
 

  getPatientAppointments(appointment){  
    console.log('getPatientAppointments',appointment);
    return new Promise<PatientAppointment[]>((resolve,reject)=>{   
      FHIR.oauth2.ready()
      .then(async (client) => { 
          const tokenResponse = client.state.tokenResponse;   
          const token = tokenResponse.access_token;
          const headers = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token) 
          .set('content-type', 'application/json')   
          let startdate = new Date(appointment.ArrivalTime);
          startdate.setDate(startdate.getDate()-2);
          let enddate = new Date(appointment.ArrivalTime);
          enddate.setDate(enddate.getDate()+2);
          const body ={
            "UserID": "1",
            "UserIDType": "External",
            "StartDate": startdate.toLocaleDateString(),
            "EndDate": enddate.toLocaleDateString(),
            "PatientID":this.patient.identifier.find((o)=>{ if(o.type){return o.type.text.toLowerCase() === "EPIC".toLowerCase()}}).value,
            "PatientIDType":"EPI",
            "IncludeAllStatuses": "true",
            "IncludeStatusList": [ ],
            "ExcludeStatusList": [ ],
            "IncludeOutsideAppointments": "false",
            "ExtraItems": [ ],
            "ExtraExtensions": [ ]
          }; 
          console.log('getPatientAppointments',body);
          this.http.post(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2013/Scheduling/Patient/GETPATIENTAPPOINTMENTS/GetPatientAppointments`,
          body,{ headers: headers }).subscribe((res:any)=>{
              console.log('getPatientAppointments',res);  
              const patientAppointments :PatientAppointment[]= (<PatientAppointment[]> res.Appointments); 
              resolve(patientAppointments);
          })   
      }) 
      .catch(()=>{reject({})}); 
    });
    
  }

  
  changeIsMax(){
    console.log(`changeIsMax`);
    this.isMax = !this.isMax;
    this.isSlideShow= false;
    setTimeout(() => {
      this.isSlideShow= true;
    }, 100);
  }  
  
  onSaveFile(){
    if(this.isProduction || this.isDebug){ 
      document.getElementById("patientFile").click();
    }
  }

  async onCPatientFile(file:File){  
    const fileAllowed =['jpg','jpeg','png','zip','pdf','doc', 'docx', 'ppt', 'xls', 'txt', 'zip', 'rar', 'mp3', 'mp4','audio']
    if(!file){
      return;
    }
    const fileSizeMb =file.size/1024/1024;
    console.log(fileSizeMb);
    if(fileSizeMb > 20){
      this.toast.presentToast("The maximum file size is 20mb.")
      return;
    } 
    console.log(file.type);
    const file_fi= fileAllowed.findIndex((el)=>{return file.type.includes(el);})
    if(file_fi == -1){
      this.toast.presentToast("The file is not allowed.")
      return;
    }  
    FHIR.oauth2.ready()
    .then(async (client) => { 
      const loading = await this.loadingController.create({ message: "Please wait ...."  });
      await loading.present(); 
      const filenames =file.name.split('.');
      const name_ = filenames[filenames.length-1];
      // const fileName = `${filenames.slice(0, filenames.length - 1).join(".")}_${TextUtilService.getRndInteger(1,100)}.${name_}`;
      const fileName = file.name;
      const uploaded_file = await UploadtostService.uploadFile(file,fileName); 
      const URL =uploaded_file.Location;

      console.log();

      const headers = new HttpHeaders() 
      .set('content-type', 'application/json')    
      const body:PatientFile = { 
        patientID:this.patient.id,
        appointmentID:this.isDebug ? this.devAppId:this.prodAppId,
        URL:URL,
        // fileName:`${this.patient.name[0].text}_${uuidv4().substring(0,3)}.${name_}` ,
        fileName:fileName,
        fileType:file.type
      }
      
      this.http.post(`${environment.baseURL}patientFile`,
      body,
      { headers: headers }).subscribe(async (res:any)=>{
          console.log(res);  
          await this.getPatientFiles();
          await loading.dismiss();
          this.toast.presentToast("Uploaded");
      })  
      console.log(uploaded_file);
    }) 
    .catch(()=>{
      
    }); 
  } 
  
  getPatientFiles() { 
    return new Promise<any>(resolve=>{ 
      FHIR.oauth2.ready()
      .then(client =>{
        if(this.isDebug){
          const headers = new HttpHeaders() 
          .set('content-type', 'application/json')   
          this.http.get(`${environment.baseURL}patientFile/listbyappointmentid?appointmentID=${this.devAppId}`,
          { headers: headers }).subscribe((patientFiles:PatientFile[])=>{    
            this.patientFiles = patientFiles;
            resolve({});
          }); 
        }else{  
          const headers = new HttpHeaders() 
          .set('content-type', 'application/json')   
          this.http.get(`${environment.baseURL}patientFile/listbyappointmentid?appointmentID=${this.prodAppId}`,
          { headers: headers }).subscribe((patientFiles:PatientFile[])=>{    
            this.patientFiles = patientFiles;
            resolve({});
          }); 
        }
      }) 
      .catch((err)=>{
        console.log(err);  
      });
    });
  }

  onOpenFile(URL:string){
    window.open(URL,"_blank");
  }
  
  async onDelete(patientFilesID:string){
    const alert = await this.alertController.create({ 
      header: 'Delete!',
      message: 'Do you want to permanentely <strong>delete<strong>?',
      buttons: [
        {
          text: 'No',
          role: 'no',
          cssClass: 'secondary',
          handler: (blah) => { 
          }
        }, {
          text: 'Yes',
          handler: async () => { 
            const loading = await this.loadingController.create({ message: "Please wait ...."  });
            await loading.present();
            this.http.delete(`${environment.baseURL}patientFile?patientFilesID=${patientFilesID}`).subscribe(async (res:any)=>{
              this.getPatientFiles();
              await loading.dismiss();
              this.toast.presentToast("Deleted");
            })  
          }
        }
      ]
    }); 
    await alert.present();
  }


  
  
  initProdChime() {
    return new Promise<any>((resolve)=>{  
      FHIR.oauth2.ready()
      .then(async (client) => {   
        const body  = {
          Provider: {
            ID:this.practitioner.id
          },
          PatientID:this.patient.id,
          TenantID:this.tenantModel.TenantID,
          AppointmentID:this.prodAppId,
          AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
          PatientName:this.patient.name[0].text, 
          ScheduledAt:this.appointmentFhirModel.start,
          ScheduledEndAt:this.appointmentFhirModel.end,
          FirstName:this.patient.name[0].given[0],
          LastName:this.patient.name[0].family,
          Gender:this.patient.gender,
          BirthDate:this.patient.birthDate
        }
        await new Promise<any>((resolve)=>{
          this.cusHttp.post(`chimeApi/createAppointment`,body)
          .subscribe((el:any)=>{resolve({})}); 
        }) 

        this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.prodAppId}&TenantID=${this.tenantModel.TenantID}`,{})
        .subscribe((el:any)=>{ 
          this.MeetingID = el.MeetingID;  
          this.prodKeepMeAlive();
          resolve({}); 
          this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.patient.name[0].text+"&appointmentID="+this.prodAppId+"&iam=patient"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);    
        });  
      }) 
    })
  }
  
  prodKeepMeAlive() { 
    const inter = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.prodAppId}&MeetingID=${this.MeetingID}&iam=patient&computerID=${this.patientDeviceID}`,{})
      .subscribe((el:any)=>{  
        console.log("keepmealive",el);
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.prodKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => {
              clearInterval(inter);
              await this.initProdChime(); 
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }
  
  initDevChime() {
    return new Promise<any>(async (resolve)=>{    
      const body  = {
        Provider: {
          ID:this.practitioner.id
        },
        PatientID:this.patient.id,
        TenantID:this.tenantModel.TenantID,
        AppointmentID:this.devAppId,
        AppointmentName:this.appointmentFhirModel.serviceType[0].coding[0].display, 
        PatientName:this.patient.name[0].text, 
        ScheduledAt:this.appointmentFhirModel.start,
        ScheduledEndAt:this.appointmentFhirModel.end,
        FirstName:this.patient.name[0].given[0],
        LastName:this.patient.name[0].family,
        Gender:this.patient.gender,
        BirthDate:this.patient.birthDate
      }
      await new Promise<any>((resolve)=>{
        this.cusHttp.post(`chimeApi/createAppointment`,body)
        .subscribe((el:any)=>{resolve({})}); 
      })

      this.cusHttp.post(`chimeApi/checkMeeting?AppointmentID=${this.devAppId}&TenantID=${this.tenantModel.TenantID}`,{})
      .subscribe((el:any)=>{  
        this.MeetingID = el.MeetingID;
        this.devKeepMeAlive();
        resolve({});  
        this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.chime+'?m='+el.MeetingID+"&n="+this.patient.name[0].text+"&appointmentID="+ this.devAppId+"&iam=patient"+"&isRecordDefaultOn="+this.tenantModel.isRecordDefaultOn+"&isNlpDefaultOn="+this.tenantModel.isNlpDefaultOn);  
      }); 

    })
  }

  devKeepMeAlive() { 
    const inter = setInterval(()=>{
      this.cusHttp.post(`chimeApi/keepmealive?AppointmentID=${this.devAppId}&MeetingID=${this.MeetingID}&iam=patient&computerID=${this.patientDeviceID}`,{})
      .subscribe((el:any)=>{ 
        console.log("keepmealive",el);
        if(el.isPatientOnline && el.isProviderOnline){
          this.isBothOnline = true;
          this.serverDate = el.serverDate;
          clearInterval(inter);
          this.devKeepMeAlive();
        }
        if(!el.success){
          if(el.isNeedRestart){ 
            setTimeout(async () => { 
              clearInterval(inter);
              await this.initDevChime();
            }, 3000);
          } 
        }
      });
    },this.isBothOnline ? 1000*15 : 1000*5);
  }

  initDevice() {
    this.patientDeviceID= localStorage.getItem("patient-uid");
    if(!this.patientDeviceID){
      this.patientDeviceID = uuidv4();
      localStorage.setItem("patient-uid",this.patientDeviceID);
    } 
  }










  async onMyChartError(){ 
    const alert = await this.alertController.create({
      message: 'Session expired, please go back to MyChart.',
      backdropDismiss:false
    });
  
    await alert.present(); 
    this.loading.dismiss();
  } 
  
  async alert(message){ 
    console.log("alert",message);
    if(message=='' || !message){message = 'Session expired, please go back to MyChart.'}
    const alert = await this.alertController.create({
      message: message,
      backdropDismiss:false
    }); 
    await alert.present();  
  } 

  async noAppointment() {
    const alert = await this.alertController.create({
      message: 'Have no appointment scheduled.',
      backdropDismiss:false
    });
    await alert.present();  
    await this.loading.dismiss();
  }


  onToggleSupportModal(){
    console.log("onToggleSupportModal",document.getElementById("support").style.display);
    if(document.getElementById("support").style.display == "none"){
      document.getElementById("support").style.display = "grid";
    }else{
      document.getElementById("support").style.display = "none";
    }
  }
}