import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core'; 
import { Practitioner } from '../models/practitioner.model';
import { ProviderModel } from '../models/provider.model';

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {

  constructor(private http:HttpClient) { }

  get(clientID,token,PatientID,MyChartAccountID,VisitTypeID){ 
    return new Promise<ProviderModel>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
      const arrdate = new Date();
      let enddate = new Date();
      enddate.setDate(enddate.getDate()+2);
      const body ={
        "PatientID": PatientID,
        "PatientIDType": "EPI",
        "Centers": [ "" ],
        "MyChartAccountID": MyChartAccountID,
        "MyChartAccountIDType": "External",
        "VisitType": {
          "ID": VisitTypeID,
          "Type": "External"
        }
      };  
      this.http.post(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2019/PatientAccess/External/GetProviders/Epic/Patient/Scheduling2019/GetProviders`,
      body,{ headers: headers }).subscribe((res:any)=>{
          // console.log('getProviderAppointments',res);  
          // const providerAppointments :ProviderAppointment[]= (<ProviderAppointment[]> res.Appointments); 
          // resolve(providerAppointments);
      })   

    })
  }

  getPractitioner(clientID,token,reference){ 
    return new Promise<Practitioner>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json') ;
      this.http.get(reference,
      { headers: headers }).subscribe((res:Practitioner)=>{
        resolve(res);
      })   

    })
  }

  getProviderAppointments(clientID,token,PatientID,MyChartAccountID,VisitTypeID){ 
    return new Promise<ProviderModel>((resolve)=>{   
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
      const arrdate = new Date();
      let enddate = new Date();
      enddate.setDate(enddate.getDate()+2);
      const body ={
        "UserID": "1",
        "UserIDType": "External",
        "StartDate": "t-7",
        "EndDate": "t",
        "CombineDepartments": "true",
        "ResourceType": "",
        "Specialty": "",
        "ExtraItems": [
          
        ],
        "Providers": [
          {
            "ID": "E1000",
            "IDType": "external",
            "DepartmentID": "",
            "DepartmentIDType": ""
          }
        ],
        "Departments": [ ],
        "Subgroups": [ ],
        "ExtraExtensions": [ ]
      };  
      this.http.post(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2013/Scheduling/Provider/GetProviderAppointments/Scheduling/Provider/Appointments`,
      body,{ headers: headers }).subscribe((res:any)=>{
          // console.log('getProviderAppointments',res);  
          // const providerAppointments :ProviderAppointment[]= (<ProviderAppointment[]> res.Appointments); 
          // resolve(providerAppointments);
      })   

    })
  }
}
