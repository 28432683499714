import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {

  constructor( 
    private toastController : ToastController
  ) { }
  async presentToast(msg,duration=6000) {
    const toast = await this.toastController.create({
      message: msg,
      color: 'primary',
      duration: duration, 
    });
    toast.present();
  } 
   
  async errorToast(msg,duration?) {
    const toast = await this.toastController.create({
      message: msg,
      color: 'danger',
      duration: duration ? duration : 6000, 
    });
    toast.present();
  } 
  async warningToast(msg,duration?) {
    const toast = await this.toastController.create({
      message: msg,
      color: 'warning',
      duration: duration ? duration : 6000, 
    });
    toast.present();
  } 
  
}
