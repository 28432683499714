import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationModel } from '../models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http:HttpClient) { }
  
  getLocation(clientID,token,reference,locationID?){
    return new Promise<LocationModel>((resolve,reject)=>{   
     
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
     
      let startdate = new Date();
      startdate.setDate(startdate.getDate()-2);
      let enddate = new Date();
      enddate.setDate(enddate.getDate()+2); 
 
      this.http.get(reference,
       { headers: headers }).subscribe((res:any)=>{ 
          resolve(res);
      })   
    });
  }
}
