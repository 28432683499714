import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DoctorDesignPageRoutingModule } from './doctor-design-routing.module';

import { DoctorDesignPage } from './doctor-design.page';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components/shared-components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedComponentsModule,
    DoctorDesignPageRoutingModule
  ],
  declarations: [DoctorDesignPage]
})
export class DoctorDesignPageModule {}
