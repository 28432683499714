import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedComponentsModule } from './shared/modules/shared-components/shared-components.module';
import { SharedPipesModule } from './shared/modules/shared-pipes/shared-pipes.module';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { CapitalizeMedicineWordPipe } from './shared/pipes/capitalize-medicine-word.pipe'; 
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    AmplifyAngularModule, 
    SharedComponentsModule.forRoot(),
  SharedPipesModule.forRoot()],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}

//https://xd.adobe.com/view/e054462e-e778-4911-98b5-cd51ddc04efb-b507/ 
//CLI to deploy in AWS CHIME deploy.js -s stack-chime-virtu-visit-v2 -b bucket-chime-virtu-visit-v2
//CLI to deploy in AWS CHIME deploy.js -s stack-chime-virtu-visit-v2-1 -b bucket-chime-virtu-visit-v2-1
//CLI to deploy in AWS CHIME deploy.js -s s-vv-chime-transcript -b bucket-chime-vv-chime-transcript 
//CLI to deploy in AWS CHIME deploy.js -s s-vv-chime-transcript -b bucket-chime-vv-chime-transcript

// Endpoint: https://u994zv9ne1.execute-api.us-east-1.amazonaws.com/Prod/
// Messaging WSS URL: wss://gyjo08p55c.execute-api.us-east-1.amazonaws.com/Prod
// https://staging.epic.vv.my-compass.io/
// https://dev-nationalcoordinationcenter.cs200.force.com/Connect/s/virtuvisit?id=a2N5B00000058om