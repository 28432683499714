import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MeetingPageRoutingModule } from './meeting-routing.module';

import { MeetingPage } from './meeting.page';
import { SharedComponentsModule } from 'src/app/shared/modules/shared-components/shared-components.module';
import { SharedPipesModule } from 'src/app/shared/modules/shared-pipes/shared-pipes.module';
import { ViewNotesComponent } from './components/view-notes/view-notes.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule, 
    MeetingPageRoutingModule,
    SharedPipesModule,
    SharedComponentsModule
  ],
  declarations: [MeetingPage,ViewNotesComponent]
})
export class MeetingPageModule {}
