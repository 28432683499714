import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FHIR from "fhirclient";
import { DocumentReference } from 'src/app/models/documentreference.model';
// import { PastEncounter } from 'src/app/models/past-encounter.model';

@Component({
  selector: 'app-view-notes',
  templateUrl: './view-notes.component.html',
  styleUrls: ['./view-notes.component.scss'],
})
export class ViewNotesComponent implements OnInit {
  @Input() pastEncounter : DocumentReference;
  notesHtmls:any[]=[];
  constructor(private http:HttpClient,
    private modalCtrl:ModalController) { }

  ngOnInit() {  
    // this.getBinaryFile(this.notes[0].attachment.url);
    this.initNotes();
  } 

  async initNotes() { 
    console.log(this.pastEncounter);   
    for(let content of this.pastEncounter.content){ 
      const noteHtml =await this.getBinaryFile(content.attachment.url);
      console.log(noteHtml);   
      this.notesHtmls.push(noteHtml);
    } 
  }

  async onBack(){
    await this.modalCtrl.dismiss();
  }

  async getBinaryFile(url){ 
    return await new Promise<string>((resolve)=>{ 
      FHIR.oauth2.ready() 
      .then((client:any)=>{
          const token = client.state.tokenResponse.access_token;
          const headersHtml = new HttpHeaders()
          .set("Epic-Client-ID", environment.patientLaunchClientID) 
          .set('Authorization', "Bearer "+ token)  
          .set('content-type', 'text/html')     
          this.http.get(`${environment.appochard_baseurl}R4/${url}`,
          { headers: headersHtml,responseType:"text" }).subscribe((note:string)=>{ 
            console.log(note);   
            resolve(note);
          }); 
      })
      .catch(()=>{  
      });
    })
  }
}
