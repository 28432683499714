import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'; 
import * as FHIR from "fhirclient"; 
import { TenantService } from 'src/app/services/tenant.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-launch',
  templateUrl: './launch.page.html',
  styleUrls: ['./launch.page.scss'],
})
export class LaunchPage implements OnInit {
  tenantId=null;
  constructor(private location:Location,
    private route:ActivatedRoute,
    private tenantService:TenantService,
    private router:Router) {  

    this.tenantId = this.route.snapshot.queryParamMap.get('orgID');
    localStorage.setItem("patientSideOrgID",this.tenantId);

    const urlTree = this.router.createUrlTree([], {
      queryParams:{ 
        orgID:this.tenantId,
        iss:environment.appochard_baseurl+"STU3"
      },
      queryParamsHandling: 'merge',
      preserveFragment: true 
    }); 
    this.location.go(urlTree.toString());
  }
 
  async ngOnInit() {
    const clientId= environment.patientLaunchClientID; 
    const tenant = await this.tenantService.getWithConfig(this.tenantId);
    let redirect_uri = "app";
    if(tenant.services == "emr-ready"){
      redirect_uri = "emr-ready";
    }
    FHIR.oauth2.authorize({ 
      'client_id': clientId, 
      'scope':  `patient/Appointment.Read patient/Binary.Read patient/CarePlan.Read patient/Condition.Read patient/DocumentReference.Read  patient/DocumentReference.Create patient/DocumentReference.Write patient/Encounter.Read patient/Medication.Read patient/MedicationRequest.Read patient/MedicationStatement.Read patient/Patient.Read patient/Questionnaire.Read patient/QuestionnaireResponse.Read patient/QuestionnaireResponse.Write`,
      'redirect_uri': window.location.origin+"/"+redirect_uri 
    }).catch((e)=>{ 
      window.location.reload();
    })
  } 

  
}

