import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PatientDemographicsModel } from '../models/patient.demographics.model';
import { PatientAppointment } from '../models/patientappoinment.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http:HttpClient) { }
  
  getPatientAppointments(clientID,token,PatientID){ 
     
    return new Promise<PatientAppointment[]>((resolve,reject)=>{   
     
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json')   
     
      let startdate = new Date();
      startdate.setDate(startdate.getDate()-2);
      let enddate = new Date();
      enddate.setDate(enddate.getDate()+2);
      const body ={
        "UserID": "1",
        "UserIDType": "External",
        "StartDate": startdate.toLocaleDateString(),
        "EndDate": enddate.toLocaleDateString(),
        "PatientID":PatientID,
        "PatientIDType":"EPI",
        "IncludeAllStatuses": "true",
        "IncludeStatusList": [ ],
        "ExcludeStatusList": [ ],
        "IncludeOutsideAppointments": "false",
        "ExtraItems": [ ],
        "ExtraExtensions": [ ]
      }; 
      console.log('getPatientAppointments',body);
      this.http.post(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2013/Scheduling/Patient/GETPATIENTAPPOINTMENTS/GetPatientAppointments`,
      body,{ headers: headers }).subscribe((res:any)=>{
          console.log('getPatientAppointments',res);  
          const patientAppointments :PatientAppointment[]= (<PatientAppointment[]> res.Appointments); 
          resolve(patientAppointments);
      })   
    });
  }


  getPatientDemographics(clientID,token,PatientID,MyChartAccountID){ 

    return new Promise<PatientDemographicsModel[]>((resolve,reject)=>{    
      const headers = new HttpHeaders()
      .set("Epic-Client-ID", clientID) 
      .set('Authorization', "Bearer "+ token) 
      .set('content-type', 'application/json') ;
       
      this.http.get(`https://apporchard.epic.com/interconnect-aocurprd-oauth/api/epic/2019/PatientAccess/Patient/GetPatientDemographics/Patient/Demographics?PatientID=${PatientID}&PatientIDType=EPI&MyChartAccountID=${MyChartAccountID}&MyChartAccountIDType=external`,
      { headers: headers }).subscribe((res:any)=>{  
          resolve(res);
      })   
    });

  }

  saveCPatientDemoGraphics(PatientID, PatientDemoGraphics){  
    return new Promise<any>((resolve)=>{  
      const headers = new HttpHeaders() 
      .set('content-type', 'application/json') ;
      
      const body = {
        PatientID,
        PatientDemoGraphics
      }
      
      this.http.post(`${environment.baseURL}chimeApi/savePatientDemoGraphics`,
      body,{ headers: headers }).subscribe((res:any)=>{  
          resolve({});
      })   

    })  
  }
}
