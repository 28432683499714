import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TextUtilService } from 'src/app/core/utils/text.util.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-patient-design',
  templateUrl: './patient-design.page.html',
  styleUrls: ['./patient-design.page.scss'],
})
export class PatientDesignPage implements OnInit {
  isMax= false;
  
  chimeIframe:SafeResourceUrl;
  navLinks=[ { icon:"../../../assets/image/nav1.png", title:"Write a Prescription", }, { icon:"../../../assets/image/nav2.png", title:"Place an Order", }, { icon:"../../../assets/image/nav3.png", title:"Submit a Referral", }, { icon:"../../../assets/image/nav4.png", title:"Schedule a Visit", }, { icon:"../../../assets/image/nav5.png", title:"Send a Message", }, { icon:"../../../assets/image/nav6.png", title:"Back to Chart", } ]
  cTimers:any[]=[{"type":"days","number":"04"},{"type":"hours","number":"06"},{"type":"min","number":"20"},{"type":"sec","number":"10"}];

  constructor( 
    public sanitizer: DomSanitizer ) { }
  ngOnInit() {
    this.init();
  }

  init() {
    // TextUtilService.counDownTimer("July 10, 2021 15:00:00",(res:any[])=>{
    //   console.log(JSON.stringify(res)); 
    //   this.cTimers = res;
    // }); 
    // this.chimeIframe = this.sanitizer.bypassSecurityTrustResourceUrl('https://f4glx76b8a.execute-api.us-east-1.amazonaws.com/Prod/?m='+uuidv4()+"&n=Juan"); 
  } 
 
}
