export const environment = {
  appVersion: require('../../package.json').version + '-uat',

  production: false,
  environment: "uat",
  debug: true, 

  appochard_baseurl:"https://apporchard.epic.com/interconnect-aocurprd-oauth/api/FHIR/",
  redirect_uri :"https://www.dev.ccn.my-compass.io/app",
  redirect_uri_doctor: "https://www.dev.ccn.my-compass.io/provider",  
  baseURL:"https://dev-api.ccn.my-compass.io/",
  patientLaunchClientID:"05a60ef5-4ea8-4915-a68f-bd72666740b4",
  providerLaunchClientID:"5ea455d4-aca8-4311-a1e6-ad20e9feea96",
  chime:"https://kgtqna9syc.execute-api.us-east-1.amazonaws.com/Prod/",
 
 
  ACCESS_KEY_ID : "AKIAQQZCENXVIE5HZCYY",
  SECRET_ACCESS_KEY : "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",
  AWS_REGION : "us-east-1",
  S3_BUCKET : "ccn-dev-patients-files" 
   
};

