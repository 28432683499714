import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomColor'
})
export class RandomColorPipe implements PipeTransform {

  colors=[
    "00838F","0277BD","1565C0",
    "283593","4527A0","6A1B9A","AD1457","C62828",
    
    "37474F","424242","4E342E","D84315","EF6C00","FF8F00","F9A825",
    "9E9D24","558B2F","2E7D32","00695C","00838F","0277BD","1565C0",
    "283593","4527A0","6A1B9A","AD1457","C62828",
    
    "37474F","424242","4E342E","D84315","EF6C00","FF8F00","F9A825",
    "9E9D24","558B2F","2E7D32","00695C",
    "37474F","424242","4E342E","D84315","EF6C00","FF8F00","F9A825",
    "9E9D24","558B2F","2E7D32","00695C","00838F","0277BD","1565C0",
    "283593","4527A0","6A1B9A","AD1457","C62828"
  ]
  transform(value: unknown, ...args: unknown[]): unknown { 
    return "#"+this.colors[Math.floor(Math.random()*(this.colors.length-1-0) + 0)];;
  }

}
