import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentDiscountPipe } from '../../pipes/percent-discount.pipe';
import { SizeShortenTextPipe } from '../../pipes/size-shorten-text.pipe';
import { BadgeText9onlyPipe } from '../../pipes/badge-text-9only.pipe';
import { NumberOfStarsPipe } from '../../pipes/number-of-stars.pipe';
import { RandomColorPipe } from '../../pipes/random-color.pipe';
import { CapitalizeMedicineWordPipe } from '../../pipes/capitalize-medicine-word.pipe';



@NgModule({
  declarations: [PercentDiscountPipe,CapitalizeMedicineWordPipe,
    SizeShortenTextPipe,BadgeText9onlyPipe,
    NumberOfStarsPipe,RandomColorPipe],
  exports: [PercentDiscountPipe,CapitalizeMedicineWordPipe,
    SizeShortenTextPipe,BadgeText9onlyPipe,
    NumberOfStarsPipe,RandomColorPipe],
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule {
  static forRoot() {
    return {
        ngModule: SharedPipesModule,
        providers: [],
    };
  }
 }
