import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as FHIR from "fhirclient"; 
import { environment } from 'src/environments/environment';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-launch-doctor',
  templateUrl: './launch-doctor.page.html',
  styleUrls: ['./launch-doctor.page.scss'],
})
export class LaunchDoctorPage implements OnInit {
  tenantId=null;

  constructor(private location:Location,
    private route:ActivatedRoute,
    private tenantService:TenantService,
    private router:Router) {     
      
    this.tenantId = this.route.snapshot.queryParamMap.get('orgID');
    localStorage.setItem("providerSideOrgID",this.tenantId);

    const urlTree = this.router.createUrlTree([], {
      queryParams:{ 
        orgID:this.tenantId,
        iss:environment.appochard_baseurl+"STU3"
      },
      queryParamsHandling: 'merge',
      preserveFragment: true 
    }); 
    this.location.go(urlTree.toString());
  }
 
  async ngOnInit() {
    const clientId= environment.providerLaunchClientID; 
    const tenant = await this.tenantService.getWithConfig(this.tenantId);
    let redirect_uri = "provider";
    if(tenant.services == "emr-ready"){
      redirect_uri = "emr-ready-provider";
    }

    FHIR.oauth2.authorize({ 
      'client_id': clientId, 
      // 'scope':  `patient/Appointment.Read patient/Binary.Read patient/CarePlan.Read patient/Condition.Read patient/DocumentReference.Read  patient/DocumentReference.Create patient/DocumentReference.Write patient/Encounter.Read patient/Medication.Read patient/MedicationRequest.Read patient/MedicationStatement.Read patient/Patient.Read patient/Questionnaire.Read patient/QuestionnaireResponse.Read patient/QuestionnaireResponse.Write`,
      'scope':  `patient/Appointment.Read patient/Binary.Read patient/CarePlan.Read patient/Condition.Read patient/DocumentReference.Read  patient/DocumentReference.Create patient/DocumentReference.Write patient/Encounter.Read patient/Medication.Read patient/MedicationRequest.Read patient/MedicationStatement.Read patient/Patient.Read patient/Questionnaire.Read patient/QuestionnaireResponse.Read patient/QuestionnaireResponse.Write`,
      'redirect_uri': window.location.origin+"/"+redirect_uri 
    }).catch((e)=>{ 
      window.location.reload();
    })
  }  
}

