import { Component, OnInit } from '@angular/core';
import { TextUtilService } from 'src/app/core/utils/text.util.service';

@Component({
  selector: 'app-doctor-design',
  templateUrl: './doctor-design.page.html',
  styleUrls: ['./doctor-design.page.scss'],
})
export class DoctorDesignPage implements OnInit {
  isMax= false;
  
  navLinks=[ { icon:"../../../assets/image/nav1.png", title:"Write a Prescription", }, { icon:"../../../assets/image/nav2.png", title:"Place an Order", }, { icon:"../../../assets/image/nav3.png", title:"Submit a Referral", }, { icon:"../../../assets/image/nav4.png", title:"Schedule a Visit", }, { icon:"../../../assets/image/nav5.png", title:"Send a Message", }, { icon:"../../../assets/image/nav6.png", title:"Back to Chart", } ]
  cTimers:any[]=[{"type":"days","number":"04"},{"type":"hours","number":"06"},{"type":"min","number":"20"},{"type":"sec","number":"10"}];

  ngOnInit() {
    this.init();
  }

  init() {
    // TextUtilService.counDownTimer("July 10, 2021 15:00:00",(res:any[])=>{
    //   console.log(JSON.stringify(res)); 
    //   this.cTimers = res;
    // });
  } 
}
